<template>
  <b-form-group
    id="radio-calculate-band-in"
    label="Calculate bands in"
    label-cols-md="4"
    v-slot="{ ariaDescribedby }"
  >
    <b-form-radio-group
      :aria-describedby="ariaDescribedby"
      buttons
      :disabled="!DetailedResults"
      id="radio-calculate-band-in"
      name="radios-btn-default"
      :options="BandUnits"
      v-model="selected"
      button-variant="outline-primary"
    />
  </b-form-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import {
  BFormGroup,
  BFormRadioGroup,
} from 'bootstrap-vue';

import Constants from '@/store/Constants';

const {
  TravelBandsStore: TBS,
} = Constants;

export default {
  components: {
    BFormGroup,
    BFormRadioGroup,
  },
  computed: {
    ...mapGetters({
      BandUnits: `${TBS.Name}/BandUnits`,
      DetailedResults: 'addressSearchStore/detailedResults',
      SelectedBandUnit: `${TBS.Name}/SelectedBandUnit`,
    }),
    addressDescription() {
      return this.DetailedResults?.description;
    },
    selected: {
      get() {
        return this.SelectedBandUnit;
      },
      async set(s) {
        await this.ChangeSelectedBandUnit(s);
      },
    },
  },
  methods: {
    ...mapActions({
      ChangeSelectedBandUnit: `${TBS.Name}/${TBS.Actions.ChangeBandUnits}`,
    }),
  },
  name: 'CalculateBandsIn',
};
</script>

<style></style>
