import Vue from 'vue';
import VueRouter from 'vue-router';
import Travelbands from '@/views/Travelbands.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/travel-bands',
    name: 'TravelBands',
    component: Travelbands,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
