<template>
  <b-form-group class="landmarks-dropdown">
    <b-dropdown
      :disabled="!DetailedResults"
      variant="primary"
    >
      <template #button-content>
        SELECT LANDMARKS
      </template>
      <b-form-checkbox-group
        class="p-2 menuItems"
        :options="Landmarks"
        stacked
        v-model="selectedLandmarks"
      />
    </b-dropdown>
  </b-form-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  BDropdown,
  BFormCheckboxGroup,
  BFormGroup,
} from 'bootstrap-vue';

import Constants from '@/store/Constants';

const {
  TravelBandsStore: TBS,
} = Constants;

export default {
  components: {
    BDropdown,
    BFormCheckboxGroup,
    BFormGroup,
  },
  data() {
    return {
      selectedLandmarks: [],
    };
  },
  computed: {
    ...mapGetters({
      DetailedResults: 'addressSearchStore/detailedResults',
      Landmarks: `${TBS.Name}/Landmarks`,
    }),
  },
  methods: {
    ...mapActions({
      ChangeSelectedLandmarks: `${TBS.Name}/${TBS.Actions.ChangeLandMarks}`,
    }),
  },
  name: 'LandMarkSelector',
  watch: {
    async selectedLandmarks(newV) {
      if (!newV) {
        return;
      }
      this.ChangeSelectedLandmarks(newV);
    },
  },
};
</script>

<style>
.landmarks-dropdown .dropdown-menu{
  width: 100%;
}
@media screen and (max-width: 500px) {
  .menuItems {
    margin: 0.5em!important;
  }
}
</style>
