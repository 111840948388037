export default {
  ACTIONS: {
    LOAD: 'Load',
  },

  GETTERS: {
    DATA: 'Data',
    IS_LOADING: 'IsLoading',
  },

  MUTATIONS: {
    SET_DATA: 'SetData',
    SET_IS_LOADING: 'SetIsLoading',
    RESET: 'Reset',
  },
  AwsAxiosStore: {
    Mutations: {
      SetToken: 'SetToken',
      SetKey: 'SetKey',
      SetTokenRefreshStrategy: 'SetTokenRefreshStrategy',
    },
    Name: 'awsAxiosStore',
    StateVars: {
      Instance: 'instance',
      Key: 'key',
      Token: 'token',
      TokenRefreshStrategy: 'tokenRefreshStrategy',
    },
  },
  TravelBandsStore: {
    Actions: {
      ChangeBandUnits: 'ChangeBandUnits',
      ChangeCoordinate: 'ChangeCoordinate',
      ChangeLandMarks: 'ChangeLandMarks',
      ChangeNumberOfBands: 'ChangeNumberOfBands',
      ChangeWidthOfBands: 'ChangeWidthOfBands',
    },
    Getters: {
      AxiosInstance: 'AxiosInstance',
      BandUnits: 'BandUnits',
      Landmarks: 'Landmarks',
      SelectedBandUnit: 'SelectedBandUnit',
      SelectedLandmarks: 'SelectedLandmarks',
      SelectedNoOfBands: 'SelectedNoOfBands',
      SelectedWidthBand: 'SelectedWidthBand',
    },
    Mutations: {
      SetAxiosInstance: 'SetAxiosInstance',
      SetBandUnits: 'SetBandUnits',
      SetCoordinate: 'SetCoordinate',
      SetLandmarks: 'SetLandmarks',
      SetSelectedBandUnit: 'SetSelectedBandUnit',
      SetSelectedLandmarks: 'SetSelectedLandmarks',
      SetSelectedNoOfBands: 'SetSelectedNoOfBands',
      SetSelectedWidthBand: 'SetSelectedWidthBand',
    },
    Name: 'travelBandsStore',
    StateVars: {
      AxiosInstance: 'axiosInstance',
      BandUnits: 'bandUnits',
      Coordinate: 'coordinate',
      Landmarks: 'landmarks',
      SelectedBandUnit: 'selectedBandUnit',
      SelectedLandmarks: 'selectedLandmarks',
      SelectedNoOfBands: 'selectedNoOfBands',
      SelectedWidthBand: 'selectedWidthBand',
    },
  },

  STATE_VARS: {
    DATA: 'data',
    IS_LOADING: 'isLoading',
  },
};
