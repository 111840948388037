/* eslint-disable global-require */
export default (category) => {
  switch (category) {
    case 'Fire Stations':
      return require('@/assets/poi-icons/fire_32x32.png');
    case 'Hospitals':
      return require('@/assets/poi-icons/hospital_32x32.png');
    case 'Police':
      return require('@/assets/poi-icons/police_32x32.png');
    case 'Schools':
      return require('@/assets/poi-icons/school_32x32.png');
    default:
      return '';
  }
};
