<template>
  <form autocomplete="off" @submit.prevent="doSearch">
    <ag-autocomplete
      autofocus
      id="autocomplete"
      class="autocomplete-component"
      :options="suggestions"
      placeholder="Address"
      :processing="loadingSuggestions"
      :text="searchText"
      @cleared="clearAddress"
      @onChosen="onChosen"
      @onFocus="fetchAwsCredentials"
      @onInput="autocomplete"
      @onSearch="doSearch"
    />
  </form>
</template>

<script>
import {
  mapGetters,
  mapMutations,
} from 'vuex';

import AgAutocomplete from '@afrigis/afrigis-vue-autocomplete';
import addressSearch from '@/mixins/addressSearch';

export default {
  components: {
    AgAutocomplete,
  },
  computed: {
    ...mapGetters({
      DetailedResults: 'addressSearchStore/detailedResults',
    }),
  },
  methods: {
    ...mapMutations({
      SetDetailedResult: 'addressSearchStore/commitDetailedResults',
    }),

  },
  mixins: [addressSearch],
  name: 'Autocomplete',
  watch: {
    DetailedResults(newV) {
      if (!newV) {
        return;
      }
      this.$emit('onChosenLocation', newV);
    },
  },
};
</script>
<style scoped>

</style>
