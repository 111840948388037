import {
  GettersUtility,
  ModuleUtilities,
  MutationsUtility,
} from '@afrigis/vuex-utilities';

import {
  dataByCoordinate,
} from '@afrigis/aws-travel-time-bands';

import Constants from '@/store/Constants';

const {
  ACTIONS,
  MUTATIONS,
  TravelBandsStore: TBS,
  STATE_VARS,
} = Constants;

const initialState = () => ({
  [STATE_VARS.DATA]: null,
  [STATE_VARS.IS_LOADING]: false,
  [TBS.StateVars.AxiosInstance]: null,
  [TBS.StateVars.BandUnits]: [
    {
      text: 'distance',
      value: 'DistanceKilometre',
    },
    {
      text: 'time',
      value: 'timeMinute',
    },
  ],
  [TBS.StateVars.Coordinate]: null,
  [TBS.StateVars.Landmarks]: [
    {
      text: 'Fire Stations',
      value: 'fireStations',
    },
    {
      text: 'Hospitals',
      value: 'hospitals',
    },
    {
      text: 'Police',
      value: 'police',
    },
    {
      text: 'Schools',
      value: 'schools',
    },
  ],
  [TBS.StateVars.SelectedBandUnit]: 'DistanceKilometre',
  [TBS.StateVars.SelectedLandmarks]: [],
  [TBS.StateVars.SelectedNoOfBands]: '2',
  [TBS.StateVars.SelectedWidthBand]: '2',
});

const STATE_PROPS_TO_EXPOSE = [
  ...Object.values(STATE_VARS),
  ...Object.values(TBS.StateVars),
];

const actions = {
  [ACTIONS.LOAD]: async (context) => {
    context.commit(MUTATIONS.SET_DATA, null);
    context.commit(MUTATIONS.SET_IS_LOADING, true);

    if (!context.state[TBS.StateVars.AxiosInstance]) {
      throw new Error('No Axios Instance Set');
    }
    if (!context.state[TBS.StateVars.Coordinate]) {
      return;
    }

    try {
      let payload = {
        axiosInstance: context.state[TBS.StateVars.AxiosInstance],
        latitude: context.state[TBS.StateVars.Coordinate].lat,
        longitude: context.state[TBS.StateVars.Coordinate].lng,
        numBands: context.state[TBS.StateVars.SelectedNoOfBands],
        bandSize: context.state[TBS.StateVars.SelectedWidthBand],
        mode: context.state[TBS.StateVars.SelectedBandUnit],
        landmarks: context.state[TBS.StateVars.SelectedLandmarks],
      };

      const landmarks = context.state[TBS.StateVars.SelectedLandmarks];
      if (landmarks && landmarks.length) {
        payload = {
          ...payload,
          landmarks,
        };
      }
      const { data } = await dataByCoordinate(payload);

      if (data.code !== 200) {
        throw new Error(data.message);
      }

      context.commit(MUTATIONS.SET_DATA, data);
    } catch (error) {
      const errorDescription = (error.response && error.response.data)
        ? error.response.data.message
        : error.message;
      throw new Error(errorDescription);
    } finally {
      context.commit(MUTATIONS.SET_IS_LOADING, false);
    }
  },
  [TBS.Actions.ChangeBandUnits]: async (context, payload) => {
    context.commit(TBS.Mutations.SetSelectedBandUnit, payload);
    await context.dispatch(ACTIONS.LOAD);
  },
  [TBS.Actions.ChangeCoordinate]: async (context, payload) => {
    context.commit(TBS.Mutations.SetCoordinate, payload);
    await context.dispatch(ACTIONS.LOAD);
  },
  [TBS.Actions.ChangeLandMarks]: async (context, payload) => {
    context.commit(TBS.Mutations.SetSelectedLandmarks, payload);
    await context.dispatch(ACTIONS.LOAD);
  },
  [TBS.Actions.ChangeNumberOfBands]: async (context, payload) => {
    context.commit(TBS.Mutations.SetSelectedNoOfBands, payload);
    await context.dispatch(ACTIONS.LOAD);
  },
  [TBS.Actions.ChangeWidthOfBands]: async (context, payload) => {
    context.commit(TBS.Mutations.SetSelectedWidthBand, payload);
    await context.dispatch(ACTIONS.LOAD);
  },
};

const state = initialState();

const getters = {
  ...GettersUtility.scaffold(state, STATE_PROPS_TO_EXPOSE),
};

const mutations = {
  ...MutationsUtility.scaffold(state, STATE_PROPS_TO_EXPOSE),
  [MUTATIONS.RESET]: ModuleUtilities.ResetToInitial(initialState),
};

export default {
  actions,
  getters,
  mutations,
  state,
};
