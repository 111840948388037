<template>
  <b-container fluid>
    <b-row>
      <h1 class="p-3 col-md-4">Travel Time Bands</h1>
      <b-col md="4" class="ml-auto p-3 text-right appCtrlButtons">
        <b-button
         title="Share This App"
         variant="outline-secondary"
         @click="shareViaWebShare">
          <b-icon-share />
        </b-button>
        <b-button
          variant="outline-secondary"
          @click="startTour"
         >
         Start Tour
        </b-button>
        <b-modal
          id="modal-1"
          title="Share This App"
          ref="fallback-modal"
          @ok="handleOk"
        >
          <b-form-textarea
            id="fallback"
            ref="toCopy"
            v-model="shareUrl"
            rows="1"
            max-rows="6"
          />
          <template #modal-footer="{ ok }">
            <b-button size="sm" variant="primary" @click="ok">
              Copy URL to Clipboard
            </b-button>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <contact-expert />
    <v-tour
      class="travel-band-tour"
      name="travelBandTour"
      :options="tourOptions"
      :steps="tourSteps"
    />
    <b-row>
      <!--Column 1 -->
      <b-col class="greyed-out mb-3" lg="8">
        <b-row class="pb-2" align-h="center">
          <autocomplete
            class="address-search col-sm-12 col-md-8  mt-2"
            @onChosenLocation="onChosenLocation"
          />
        </b-row>
        <b-alert class="text-center mt-5"
          show
          v-if="searchError"
          variant="danger"
        >
          {{ searchError }}
        </b-alert>
        <b-row :class="!!searchError ? '' : 'mt-5'">
          <b-col class="mb-2" lg="6">
            <travel-band-input />
          </b-col>
          <b-col class="mb-2" lg="6">
            <b-card
              class="mb-3"
              header-tag="header"
              no-body
            >
              <template #header>
                <h4>Map showing Travel Bands</h4>
              </template>
              <leaflet-map />
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <!--Column 2 -->
      <b-col class="mb-2" lg="4">
        <information-section />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import {
  BAlert,
  BCard,
  BContainer,
  BCol,
  BRow,
  BIconShare,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue';

import Autocomplete from '@/components/Autocomplete.vue';
import ContactExpert from '@/components/ContactExpert.vue';
import InformationSection from '@/components/InformationSection.vue';
import LeafletMap from '@/components/LeafletMap.vue';
import TravelBandInput from '@/components/TravelBandInput.vue';

import Constants from '@/store/Constants';

const {
  ACTIONS,
  GETTERS,
  AwsAxiosStore: AAS,
  TravelBandsStore: TBS,
} = Constants;

export default {
  components: {
    Autocomplete,
    BAlert,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormTextarea,
    BIconShare,
    BRow,
    ContactExpert,
    LeafletMap,
    TravelBandInput,
    InformationSection,
  },
  computed: {
    ...mapGetters({
      AwsAxiosInstance: `${AAS.Name}/${GETTERS.DATA}`,
      SelectedNoOfBands: `${TBS.Name}/${TBS.Getters.SelectedNoOfBands}`,
      SelectedWidthBand: `${TBS.Name}/${TBS.Getters.SelectedWidthBand}`,
      SelectedBandUnit: `${TBS.Name}/${TBS.Getters.SelectedBandUnit}`,
      SelectedLandmarks: `${TBS.Name}/${TBS.Getters.SelectedLandmarks}`,
      TravelBands: `${TBS.Name}/${GETTERS.DATA}`,
    }),
    shareUrl() {
      const { protocol, host, pathname } = window.location;
      const url = [`${protocol}//${host}${pathname}`];
      return url.join('');
    },
    tourOptions() {
      return {
        highlight: true,
        labels: {
          buttonSkip: 'Skip tour (Esc)',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Thanks',
        },
      };
    },
    tourSteps() {
      return [
        {
          content: 'Type an address and select your travel time bands criteria.',
          header: {
            title: 'Address Search',
          },
          params: {
            placement: 'auto',
          },
          target: '.address-search',
        },
        {
          content: 'Select the number of travel time bands',
          header: {
            title: 'Number of bands',
          },
          params: {
            placement: 'auto',
          },
          target: '.number-of-bands',
        },
        {
          content: 'Select the width of each band in kilometres',
          header: {
            title: 'Width of each band',
          },
          params: {
            placement: 'auto',
          },
          target: '.width-of-band',
        },
        {
          content: 'Select landmarks from the list',
          header: {
            title: 'Landmarks',
          },
          params: {
            placement: 'auto',
          },
          target: '.landmarks',
        },
        {
          content: 'Select your calculation preference in distance or time',
          header: {
            title: 'Calculation preference',
          },
          params: {
            placement: 'auto',
          },
          target: '.calculation-preference',
        },
      ];
    },
  },
  destroyed() {
    window.removeEventListener('iris:share-capability', this.shareViaWebShare);
    window.removeEventListener('iris:start-tour', this.startTour);
  },
  methods: {
    ...mapActions({
      LoadAwsAuthentication: `${AAS.Name}/${ACTIONS.LOAD}`,
      ChangeCoordinate: `${TBS.Name}/${TBS.Actions.ChangeCoordinate}`,
    }),
    ...mapMutations({
      SetTravelTimeBandsAxiosInstance: `${TBS.Name}/${TBS.Mutations.SetAxiosInstance}`,
    }),
    handleOk() {
      const Url = this.$refs.toCopy;
      Url.innerHTML = this.shareUrl;
      Url.select();
      document.execCommand('copy');
      this.$bvModal.msgBoxOk('URL Copied to Clipboard successfully', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    initTour() {
      const hasSeenTour = JSON.parse(localStorage.getItem('hasSeenTravelTimeBandsTour'));
      if (!hasSeenTour) {
        this.$tours.travelBandTour.start();
        localStorage.setItem('hasSeenTravelTimeBandsTour', true);
      }
    },
    async onChosenLocation(detailedResult) {
      const { location: { lat, lng } } = detailedResult;
      await this.ChangeCoordinate({
        lat,
        lng,
      });
    },
    startTour() {
      this.$tours.travelBandTour.start();
    },
    shareViaWebShare() {
      if (navigator.share) {
        navigator
          .share({
            title: document.title,
            url: this.shareUrl,
          })
          .then()
          .catch(() => {
            // Handle the error!
          });
      } else {
        this.$refs['fallback-modal'].show();
      }
    },
  },
  async mounted() {
    window.addEventListener('iris:share-capability', this.shareViaWebShare);
    window.addEventListener('iris:start-tour', this.startTour);
    this.initTour();
    await this.LoadAwsAuthentication();
    this.SetTravelTimeBandsAxiosInstance(this.AwsAxiosInstance);
  },
  name: 'TravelBands',
};
</script>
<style src="vue-tour/dist/vue-tour.css"></style>
<style>
#fallback {
  pointer-events: none;
  background-color: lightgrey;
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
.trip-cost-tour {
  z-index: 500;
}
.greyed-out {
  background-color: #D1D2D4;
  min-height: 100%;
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.4);
}
.address-search {
  position: absolute;
  width: 100%;
  z-index: 900;
}
@media screen and (max-width: 400px) {
  .appCtrlButtons {
    display: none
  }
}
</style>
