import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      suggestions: 'addressSearchStore/suggestions',
      loadingSuggestions: 'addressSearchStore/loadingSuggestions',
      token: 'addressSearchStore/token',
      detailedResults: 'addressSearchStore/detailedResults',
    }),
  },
  data() {
    return {
      autocompleteCharacterTimer: null,
      searchError: null,
      searchText: '',
    };
  },
  methods: {
    ...mapActions(
      'addressSearchStore',
      ['doAutocomplete', 'doSearch', 'doSearchDetail', 'fetchAwsCredentials', 'searchLastEnteredText'],
    ),
    ...mapMutations(
      'addressSearchStore',
      ['clearDetailedResults', 'clearSuggestions', 'LastEnteredText'],
    ),
    autocomplete(searchText) {
      if (searchText.length < 3) {
        return;
      }
      this.LastEnteredText(searchText);
      if (this.autocompleteCharacterTimer) {
        clearTimeout(this.autocompleteCharacterTimer);
      }
      this.autocompleteCharacterTimer = setTimeout(() => {
        this.searchError = null;
        this.doAutocomplete({ searchText })
          .catch(() => {
            this.searchError = 'Could not Autocomplete';
          });
      }, 300);
    },
    clearAddress() {
      this.searchText = '';
      this.searchError = null;
      this.clearDetailedResults();
      this.clearSuggestions();
    },
    onChosen(data) {
      this.searchError = null;
      this.clearDetailedResults();
      this.clearSuggestions();
      this.doSearchDetail({ data });
    },
  },
};
