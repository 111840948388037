<template>
  <div :id="mapId" class="mapContainer">
    Loading Map...
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet/dist/leaflet';
import AdressSearchMixin from '@/mixins/addressSearch';

import Constants from '@/store/Constants';

import getLandmarkIcon from '@/utils/landmarsIcons';

const locationIconUrl = require('@/assets/map-icons/location.png');

const {
  GETTERS,
  TravelBandsStore: TBS,
} = Constants;

export default {
  computed: {
    ...mapGetters({
      TravelBands: `${TBS.Name}/${GETTERS.DATA}`,
    }),
    center: () => [-25.808611, 28.256111],
    mapId: () => 'AGmap',
    defaultMapType: () => 'vector-grey',
  },
  data() {
    return {
      mapLoaded: false,
      leafletMap: null,
      searchMarker: null,
      travelBandslayers: null,
      landmarksLayers: null,
    };
  },
  methods: {
    checkIfMapLoaded() {
      this.mapLoaded = !!window.AfriGIS;
      if (this.mapLoaded) {
        return;
      }
      setTimeout(() => {
        this.checkIfMapLoaded();
      }, 500);
    },
    clearMapLayers() {
      this.clearSearchMarker();
      this.clearTravelBands();
      this.clearLandmarks();
      this.fitCountryBounds();
    },
    clearLandmarks() {
      if (this.landmarksLayers) {
        this.landmarksLayers.clearLayers();
      }
    },
    clearSearchMarker() {
      if (this.searchMarker) {
        this.leafletMap.removeLayer(this.searchMarker);
      }
    },
    clearTravelBands() {
      if (this.travelBandslayers) {
        this.travelBandslayers.clearLayers();
      }
    },
    drawTravelBands(data) {
      if (this.travelBandslayers) {
        this.travelBandslayers.clearLayers();
      }
      data.reverse().forEach((d) => {
        const style = this.getBandStyle(d.bandNum);
        const layer = L.geoJson(d.geometryInstance, {
          ...style,
          opacity: 0.65,
          weight: 2,
        });
        layer.addTo(this.travelBandslayers);
      });
      this.leafletMap.fitBounds(this.travelBandslayers.getBounds());
    },
    drawTravelLandMarks(landmarks) {
      if (this.landmarksLayers) {
        this.landmarksLayers.clearLayers();
      }
      const popupOptions = {
        autoPanPaddingTopLeft: L.point(0, 70),
        offset: [0, -7],
      };
      landmarks.forEach((landmark) => {
        const {
          category,
          latitude,
          longitude,
          name,
        } = landmark;
        const icon = L.icon({
          iconAnchor: new L.Point(16, 16),
          iconUrl: getLandmarkIcon(category),
          iconSize: [32, 32],
        });
        const landmarkLayer = L.marker([latitude, longitude], { icon })
          .bindPopup(name, popupOptions);
        landmarkLayer.addTo(this.landmarksLayers);
      });
    },
    getBandStyle(bandNum) {
      if (bandNum === 1) {
        return {
          // Dirty Pink
          color: '#a8509f',
        };
      } if (bandNum === 2) {
        return {
          // Warm Yellow
          color: ' #f6ed4a',
        };
      }
      return {
        // Teal
        color: '#2d8fa8',
      };
    },
    fitCountryBounds() {
      this.leafletMap.fitBounds(
        L.latLngBounds(
          L.latLng(-34.659234, 19.654361),
          L.latLng(-23.874582, 30.640689),
        ),
      );
    },
    initMap() {
      if (this.leafletMap) {
        return;
      }
      this.leafletMap = window.AfriGIS.map(this.mapId, {
        center: L.latLng(this.center),
        authkey: process.env.VUE_APP_AG_LEAFLET_API_KEY,
        mapType: this.defaultMapType,
        maxBounds: L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)),
        maxBoundViscosity: 0,
        maxZoom: 18,
        minZoom: 3,
        zoom: 3,
        zoomControl: true,
      });
      this.leafletMap.zoomControl.setPosition('bottomright');
      this.initIcons();

      this.travelBandslayers = L.geoJSON();
      this.travelBandslayers.addTo(this.leafletMap);

      this.landmarksLayers = L.layerGroup();
      this.landmarksLayers.addTo(this.leafletMap);
    },
    initIcons() {
      this.locationIcon = L.icon({
        shadowUrl: null,
        iconAnchor: new L.Point(16, 32),
        iconSize: new L.Point(32, 32),
        iconUrl: locationIconUrl,
      });
    },
    zoomToLocation(latitude, longitude) {
      this.clearSearchMarker();
      const coordArray = [latitude, longitude];
      this.leafletMap.setView(coordArray);
      this.leafletMap.fitBounds([coordArray]);
      this.searchMarker = L.marker(coordArray, {
        icon: this.locationIcon,
      }).addTo(this.leafletMap);
    },
  },
  name: 'LeafletMap',
  mixins: [AdressSearchMixin],
  mounted() {
    this.checkIfMapLoaded();
  },
  watch: {
    detailedResults(details) {
      if (!details || !details.location) {
        this.clearMapLayers();
        return;
      }
      this.zoomToLocation(details.location.lat, details.location.lng);
    },
    mapLoaded(newV) {
      if (!newV) {
        return;
      }
      this.initMap();
    },
    TravelBands(newV) {
      if (!newV) {
        return;
      }
      const { result: { bands = [] } } = newV;
      const landmarks = [];
      // flattern landmarks response
      bands.forEach((b) => {
        (b.landmarks ?? []).forEach((landmark) => {
          const { category, items } = landmark;
          items.forEach((item) => {
            landmarks.push({
              ...item,
              category,
            });
          });
        });
      });
      this.drawTravelBands(bands);
      this.drawTravelLandMarks(landmarks);
    },
  },
};
</script>

<style scoped>
.mapContainer {
  position: relative;
  width: 100%;
  height: 60vh;
  z-index: 1;
}

.leaflet-bar a {
  background-color: var(--primary) !important;
  color: #fff !important;
}
.leaflet-interactive {
  cursor: grab;
}
.leaflet-marker-icon {
  cursor: pointer;
}

</style>
