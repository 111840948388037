<template>
  <b-form-group
    id="number-of-bands-range"
    :label="`Number of Bands: ${selected}`"
  >
    <b-form-input
      id="number-of-bands-range"
      :disabled="!DetailedResults"
      max="3"
      min="1"
      type="range"
      v-model="selected"
    />
  </b-form-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import {
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue';

import Constants from '@/store/Constants';

const {
  TravelBandsStore: TBS,
} = Constants;

export default {
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    ...mapGetters({
      DetailedResults: 'addressSearchStore/detailedResults',
      SelectedNoOfBands: `${TBS.Name}/SelectedNoOfBands`,
    }),
    selected: {
      get() {
        return this.SelectedNoOfBands;
      },
      async set(s) {
        this.ChangeSelectedNoOfBands(s);
      },
    },
  },
  methods: {
    ...mapActions({
      ChangeSelectedNoOfBands: `${TBS.Name}/${TBS.Actions.ChangeNumberOfBands}`,
    }),
  },
  name: 'NumberOfBands',
};
</script>

<style></style>
