import Axios from 'axios';
import { GettersUtility, MutationsUtility } from '@afrigis/vuex-utilities';

import { addressDetails, addressSearch } from '@afrigis/aws-search-services';

const STATEVAR_AXIOSCANCELSOURCE = 'autocompleteAxiosCancelSource';
const STATEVAR_DETAILED_RESULTS = 'detailedResults';
const STATEVAR_KEY = 'key';
const STATEVAR_LASTENTEREDTEXT = 'lastEnteredText';
const STATEVAR_LOADING_SUGGESTIONS = 'loadingSuggestions';
const STATEVAR_SUGGESTIONS = 'suggestions';
const STATEVAR_TOKEN = 'token';

const MUTATION_SET_LOADING_SUGGESTIONS = 'loadingSuggestions';
const MUTATION_SET_SUGGESTIONS = 'commitSuggestions';
const MUTATION_CANCEL_AUTOCOMPLETE = 'cancelAutocomplete';
const MUTATION_SET_AXIOS_CANCELSOURCE = 'autocompleteAxiosCancelSource';
const MUTATION_SET_KEY = 'SetKey';
const MUTATION_SET_DETAILED_RESULTS = 'commitDetailedResults';
const MUTATION_SET_TOKEN = 'commitToken';

const ACTION_DOSEARCH = 'doSearch';
const ACTION_SEARCHADDRESS = 'searchForAddressString';

const PROXY_URL = `${process.env.VUE_APP_ROOT_API}/proxies/search/api`;

const state = {
  [STATEVAR_AXIOSCANCELSOURCE]: null,
  [STATEVAR_DETAILED_RESULTS]: null,
  [STATEVAR_KEY]: null,
  [STATEVAR_LASTENTEREDTEXT]: null,
  [STATEVAR_LOADING_SUGGESTIONS]: false,
  [STATEVAR_SUGGESTIONS]: null,
  [STATEVAR_TOKEN]: null,
};

function awsAxiosInstance(token, key) {
  const axiosInstance = Axios.create();
  axiosInstance.interceptors.request
    .use(
      (config) => {
        const authorisationHeader = `Bearer ${token}`;
        const { headers } = config;
        headers.Authorization = authorisationHeader;
        headers.common['x-api-key'] = key;
        headers.common['Access-Control-Allow-Origin'] = '*';
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );
  return axiosInstance;
}

const actions = {
  doAutocomplete: (context, payload) => new Promise((resolve, reject) => {
    context.commit(MUTATION_SET_LOADING_SUGGESTIONS, true);
    context.commit(MUTATION_SET_SUGGESTIONS, null);

    context.commit(MUTATION_CANCEL_AUTOCOMPLETE);
    context.commit(MUTATION_SET_AXIOS_CANCELSOURCE, Axios.CancelToken.source());

    const autocompleteUrl = 'https://afrigis.services/places-autocomplete/api/v3/autocomplete';

    const axiosInstance = awsAxiosInstance(
      context.state[STATEVAR_TOKEN],
      context.state[STATEVAR_KEY],
    );

    const encodedSearch = encodeURIComponent(payload.searchText);
    axiosInstance({
      url: `${autocompleteUrl}?query=${encodedSearch}`,
      cancelToken: state.autocompleteAxiosCancelSource.token,
    }).then((result) => {
      const mappedResult = result.data.result.map((r) => ({
        ...r,
        type: 'address',
      }));
      context.commit(MUTATION_SET_SUGGESTIONS, mappedResult);
      resolve();
    }).catch(() => {
      reject();
    })
      .finally(() => {
        context.commit(MUTATION_SET_LOADING_SUGGESTIONS, false);
      });
  }),
  doSearch: ({ dispatch }, payload) => new Promise(() => dispatch(ACTION_SEARCHADDRESS, payload)),
  searchLastEnteredText: ({ dispatch }) => new Promise(() => (
    dispatch(ACTION_DOSEARCH, state.lastEnteredText)
  )),
  doSearchDetail: async (context, payload) => {
    const axiosInstance = awsAxiosInstance(
      context.state[STATEVAR_TOKEN],
      context.state[STATEVAR_KEY],
    );
    try {
      const { data: { code, message, result } } = await addressDetails(
        axiosInstance,
        payload.data.seoid,
      );
      if (code !== 200) {
        throw new Error(message);
      }
      context.commit(MUTATION_SET_DETAILED_RESULTS, result);
    } catch (error) {
      const errorDescription = (error.response && error.response.data)
        ? error.response.data.message
        : error.message;
      throw new Error(errorDescription);
    }
  },
  fetchAwsCredentials: (context) => new Promise((resolve, reject) => {
    if (context.state[STATEVAR_TOKEN] && context.state[STATEVAR_KEY]) {
      return;
    }
    const requestObj = {
      url: `${PROXY_URL}/awsauthentication/`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    Axios(requestObj)
      .then((response) => {
        const {
          token,
          key,
        } = response.data.result;
        context.commit(MUTATION_SET_TOKEN, token);
        context.commit(MUTATION_SET_KEY, key);
        resolve();
      })
      .catch(() => {
        reject();
      });
  }),
  searchForAddressString: async (context, searchText) => {
    const axiosInstance = awsAxiosInstance(
      context.state[STATEVAR_TOKEN],
      context.state[STATEVAR_KEY],
    );
    try {
      context.commit(MUTATION_SET_LOADING_SUGGESTIONS, true);
      context.commit(MUTATION_SET_SUGGESTIONS, null);
      const { data: { code, message, result } } = await addressSearch(
        axiosInstance,
        searchText,
      );
      if (code !== 200) {
        throw new Error(message);
      }
      const suggestion = result.map((r) => (
        {
          seoid: r.seoid,
          description: r.formatted_address,
          latitude: r.location.lat,
          longitude: r.location.lng,
          type: 'address',
        }
      ));
      context.commit(MUTATION_SET_SUGGESTIONS, suggestion);
    } catch (error) {
      const errorDescription = (error.response && error.response.data)
        ? error.response.data.message
        : error.message;
      throw new Error(errorDescription);
    } finally {
      context.commit(MUTATION_SET_LOADING_SUGGESTIONS, false);
    }
  },
};

const getters = {
  [STATEVAR_DETAILED_RESULTS]: GettersUtility.getObjectCopy(STATEVAR_DETAILED_RESULTS),
  [STATEVAR_KEY]: GettersUtility.get(STATEVAR_KEY),
  [STATEVAR_LOADING_SUGGESTIONS]: GettersUtility.get(STATEVAR_LOADING_SUGGESTIONS),
  [STATEVAR_SUGGESTIONS]: GettersUtility.getArrayCopy(STATEVAR_SUGGESTIONS),
  [STATEVAR_TOKEN]: GettersUtility.get(STATEVAR_TOKEN),
};

const mutations = {
  autocompleteAxiosCancelSource: MutationsUtility.set(STATEVAR_AXIOSCANCELSOURCE),
  cancelAutocomplete: (stateP) => {
    if (stateP.autocompleteAxiosCancelSource) {
      const localState = stateP;
      localState.autocompleteAxiosCancelSource.cancel();
    }
  },
  clearDetailedResults: (stateP) => {
    const localState = stateP;
    localState.detailedResults = null;
  },
  clearSuggestions: (stateP) => {
    const localState = stateP;
    localState.suggestions = null;
  },
  clearOriginResult: (stateP) => {
    const localState = stateP;
    localState.detailedOriginResults = null;
  },
  clearDestResult: (stateP) => {
    const localState = stateP;
    localState.detailedDestResults = null;
  },
  [MUTATION_SET_DETAILED_RESULTS]: MutationsUtility.set(STATEVAR_DETAILED_RESULTS),
  [MUTATION_SET_TOKEN]: MutationsUtility.set(STATEVAR_TOKEN),
  [MUTATION_SET_SUGGESTIONS]: MutationsUtility.set(STATEVAR_SUGGESTIONS),
  [MUTATION_SET_LOADING_SUGGESTIONS]: MutationsUtility.set(STATEVAR_LOADING_SUGGESTIONS),
  LastEnteredText: MutationsUtility.set(STATEVAR_LASTENTEREDTEXT),
  [MUTATION_SET_KEY]: MutationsUtility.set(STATEVAR_KEY),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
