<template>
  <div>
    <p v-if="addressDescription" class="h4">Address</p>
    <p v-if="addressDescription">
      <span class="addressDetailsBackground">
        {{ addressDescription }}
      </span>
    </p>
    <p v-if="coordDescription">
      <span class="addressDetailsBackground">
        {{ coordDescription }}
      </span>
    </p>
    <b-spinner
      class="my-2"
      label="Loading..."
      small
      v-if="IsLoading"
    />
    <number-of-bands class="number-of-bands" />
    <width-of-band class="width-of-band" />
    <land-mark-selector class="landmarks" />
    <calculate-band-in class="calculation-preference" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { BSpinner } from 'bootstrap-vue';
import Constants from '@/store/Constants';

import CalculateBandIn from './CalculateBandIn.vue';
import LandMarkSelector from './LandMarkSelector.vue';
import NumberOfBands from './NumberOfBands.vue';
import WidthOfBand from './WidthOfBand.vue';

const {
  GETTERS,
  TravelBandsStore: TBS,
} = Constants;

export default {
  components: {
    CalculateBandIn,
    LandMarkSelector,
    NumberOfBands,
    BSpinner,
    WidthOfBand,
  },
  computed: {
    ...mapGetters({
      DetailedResults: 'addressSearchStore/detailedResults',
      IsLoading: `${TBS.Name}/${GETTERS.IS_LOADING}`,
    }),
    addressDescription() {
      return this.DetailedResults?.description;
    },
    coordDescription() {
      return this.DetailedResults ? `(${this.DetailedResults?.location.lat};${this.DetailedResults?.location.lng})` : null;
    },
  },
  name: 'TravelBandsInout',
};
</script>

<style scoped>
.addressDetailsBackground {
  background-color: rgba(168, 80, 159, 0.2);
  padding: 3px;
}
</style>
