<template>
  <div>
    <b-card
      id="trust-us-card"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
      text-variant="white"
    >
      <div class="content">
        <h5>Trust us for</h5>
        <ul>
          <li>SABS and ISO compliant Address data.</li>
          <li>Accurate and verified address results returned.</li>
          <li>
            Components available via API to integrate into your own solutions.
          </li>
          <li>Over 50 Million searchable address combinations.</li>
        </ul>
      </div>
    </b-card>
    <b-card class="mb-3 remove-border-card">
      <h4>Possible uses:</h4>
      <b-card-text>
        <ul>
          <li>Calculate the closest school for your children to attend.</li>
          <li>
           Get an idea of the distance to emergency
           services around you (e.g. police station,
           fire brigade, hospital).
          </li>
          <li>
            Add your own data to work out accurate distances or travel times.
          </li>
        </ul>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue';

const backgroundImage = require('@/assets/TTB_Image_1920x300.webp');

export default {
  components: {
    BCard,
    BCardText,
  },
  computed: {
    backgroundImage: () => backgroundImage,
  },
  name: 'InformationSection',
};
</script>

<style>
#trust-us-card {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 250px;
  border-bottom:0;
  border-color: white;
}
#trust-us-card .content{
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.remove-border-card {
  border-top: 0;
  border-color: white;
}
</style>
